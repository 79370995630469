
import { defineComponent } from "vue";
import axios from "axios";
import { environment } from "@/environment";


export default defineComponent({
  data() {
    return {
      rolesQuery: null,
      filterType: "",
      filterQuery: "",
      results: null,
      roleType: 1,
    };
  },
  computed: {
    idRoles(): any {
      return this.rolesQuery !== null
        ? this.rolesQuery.filter((rol) => {
            return rol.idRole !== 4;
          })
        : null;
    },
  },
  async created() {
    const response = await axios(`${environment.URL_API}rol`);
    this.rolesQuery = response.data.data;
  },
  methods: {
    async lookUsers() {
      const response = await axios(
        `${environment.URL_API}user/find-users?roleType=${this.roleType}&query=${this.filterQuery}`
      );
      this.results = response.data;
    },
    rolName(id) {
      const roles: any = [...this.idRoles];
      return this.idRoles !== null
        ? this.idRoles.find((rol) => {
            return rol.idRole == id;
          }).name
        : id;
    },
  },
});

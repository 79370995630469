// PaymentForm.vue
<template>
  <div>
    <p>Email</p>
    <div class="form-group-1">
    <input type="email" v-model="email" >
  </div>
    <p>Name</p>
    <div class="form-group-1">
    <input type="text" v-model="name" >
  </div>
  </div>
  <div class="payment-form">
    <div v-if="error" class="error">{{ error }}</div>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="card-element">Credit or debit card</label>
        <div id="card-element"></div>
      </div>
      <button class="btn tw-bg-[#00a0da] tw-text-[#FFFFFF] tw-rounded-full tw-w-[150px]" type="submit" :disabled="processing">
        {{ processing ? 'Processing...' : 'Pay' }}
      </button>
    </form>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { environment } from "@/environment";
import { email } from '@vuelidate/validators';
import { useToast } from 'vue-toast-notification';

export default {
  name: 'PaymentForm',
  props: {
    idUsuario : {
      type: Number,
      default: null
    },
    roleType : {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      stripe: null,
      elements: null,
      card: null,
      error: null,
      processing: false,
      email: '',
      name: '',
      $toast: useToast(),
      urlApi: null
    }
  },
  async mounted() {
    // Initialize Stripe
    this.stripe = await loadStripe('pk_test_51QBGj9KcHvZ8mlgQGZI8C8X9uU2PDuIjbexPD9OtJbhG4SU7UXLQAHjrSDYYEpYVX3VivtJEpltQ9YraK0qD6EMg00Jt4vwe4C');
    this.elements = this.stripe.elements();
    
    // Create card element
    this.card = this.elements.create('card');
    this.card.mount('#card-element');
    
    // Handle real-time validation errors
    this.card.addEventListener('change', ({error}) => {
      this.error = error ? error.message : '';
    });

    this.urlApi = environment.URL_API;
  },
  methods: {
    async handleSubmit() {
      this.processing = true;
      this.error = null;
      
      try {

        // 1. Create PaymentMethod
        const { paymentMethod, error: paymentMethodError } = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            email: this.email,
            name: this.name,
          }
        });

        if (paymentMethodError) {
          throw new Error(paymentMethodError.message);
        }

        // Create payment intent on the server
        const response = await fetch( environment.URL_API + 'create-subscription', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            idUsuario: this.idUsuario,
            roleType: this.roleType,
            name: this.name,
            email: this.email,
            paymentMethodId: paymentMethod.id
          })
        });
        
        const data = await response.json();
        
        if (data.error) {
          throw new Error(data.error);
        }
        
        // Confirm card payment
        const { error, paymentIntent } = await this.stripe.confirmCardPayment(
          data.clientSecret,
          {
            payment_method: {
              card: this.card,
              billing_details: {
                // Add billing details if needed
              }
            }
          }
        );
        
        if (error) {
          throw new Error(error.message);
        }

        console.log('Payment successful:', paymentIntent);
        this.$toast.success("Payment was completed successfully", { position: 'top-right', duration: 5000 });
        setTimeout(() => {
          window.location.href = 'https://realtortools.org/loginusers';
        }, 2000);
        
      } catch (err) {
        this.error = err.message;
      }
      
      this.processing = false;
    }
  },
  beforeDestroy() {
    if (this.card) {
      this.card.destroy();
    }
  }
}

</script>

<style scoped>
.payment-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.error {
  color: #dc3545;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

#card-element {
  padding: 10px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  background-color: #ffffff;
}

.form-group-1 {
  display: flex;
  align-items: center;
  padding: 10px;

}

input {
  padding-left: 30px;
  border-radius: 10px;
  height: 30px;
  width: 400px;
}
</style>
<template>

    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Aceptar pago</title>
    </head>

    <body>
        <section>
            <Stripe :amount="1000" @payment-success="handlePaymentSuccess" />
        </section>


        <!--Ejecuta el formulario de pago-->
        <div id="checkout">
            <!--Aquí se mostrará el formulario de pagos-->
        </div>
    </body>
</template>
<script>



</script>
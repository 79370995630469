import { createMemoryHistory, createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import RealtorsHomeView from '../views/Home/RealtorsHomeView.vue'
import TargetView from '../views/Home/Target/TargetView.vue'
import LoginUsersView from '../views/LoginUsers/LoginUsersView.vue'
import RegisterUsersView from '../views/LoginUsers/RegisterUsersView.vue'
import AdminView from '../views/Admin/AdminView.vue'
import SettingsView from '../views/Admin/SettingsView.vue'
import DashboardPanelView from '../views/Admin/DashboardPanelView.vue'
import SettingsPanelView from '../views/Admin/SettingsPanelView.vue'
import MessageView from '../views/Admin/MessageView.vue'
import ResidentialView from '../views/Admin/ResidentialView.vue'
import MortgagePaymentView from '../views/Admin/MortgagePaymentView.vue'
import GetLoanView from '../views/Admin/GetLoanView.vue'
import TotalCashMonthlyPaymentView from '../views/Admin/TotalCashMonthlyPaymentView.vue'
import TotalFinancialMonthlyPaymentView from '../views/Admin/TotalFinancialMonthlyPaymentView.vue'
import BudgetView from '../views/Admin/BudgetView.vue'
import FlipPropertyReadyView from '../views/Admin/FlipPropertyReadyView.vue'
import FlipAsIsView from '../views/Admin/FlipAsIsView.vue'
import PasiveFinancialView from '../views/Admin/PasiveFinancialView.vue'
import PasiveCashView from '../views/Admin/PasiveCashView.vue'
import ShortTermCashView from '../views/Admin/ShortTermCashView.vue'
import ShortTermFinancialView from '../views/Admin/ShortTermFinancialView.vue'
import ContactRealtorView from '../views/Contact/ContactRealtorView.vue'
import AdminContactsView from '../views/Admin/AdminContactsView.vue'
import ForeignViewVue from '@/views/Admin/ForeignView.vue'
import CompanyView from '../../src/views/VideoEmail/CompanyView.vue'
import InspectorView from '../../src/views/VideoEmail/InspectorView.vue'
import MloView from '../../src/views/VideoEmail/MloView.vue'
import RegisterUsersPaymentsView from '@/views/LoginUsers/RegisterUsersPaymentsView.vue'
import FaqsViewVue from '../views/Home/Target/FaqsView.vue'
import VistaVideoMloView from '../views/Home/VistaVideoMloView.vue'
import VistaVideoRealtorView from '../views/Home/VistaVideoRealtorView.vue'
import SupportMessagesView from '@/views/Admin/SupportMessagesView.vue'
import MLOOverviewView from '@/views/Home/MLOOverviewView.vue'
import RealtorsOverviewView from '@/views/Home/RealtorsOverviewView.vue'
import LandingMLO from '@/views/Home/LandingMLO.vue'
import LandinTitleCompany from '@/views/Home/LandingTitleCompany.vue'
import LandingInspector from '@/views/Home/LandingInspector.vue'
import LandingRealtors from '@/views/Home/LandingRealtors.vue'
import VideoRealtor from '@/views/Home/VideoRealtor.vue'
import AboutView from '@/views/AboutView.vue'
import TitleCompanyDashboard from '@/components/TitleCompanyDashboard.vue'
import Checkout from '@/views/LoginUsers/Checkout.vue'
import { vModelCheckbox } from 'vue'
import RealtorDashboard from '../views/Admin/RealtorDashboard.vue'
import RealtorScope from '../views/Admin/RealtorScope.vue'





const routes: Array<RouteRecordRaw> = [

    {
        path: '/',
        name: 'home',
        component: RealtorsHomeView,
    },

    {
        path: '/videoTitleCompany',
        name: 'companyview',
        component: CompanyView,
    },
    {
        path: '/videoInspector',
        name: 'inspectorview',
        component: InspectorView,
    },
    {
        path: '/videoMortgageLoanOriginator',
        name: 'mloview',
        component: MloView,
    },
    {
        path: '/target',
        name: 'target',
        component: TargetView
    },
    {
        path: '/vmlo',
        name: 'vmlo',
        component: VistaVideoMloView
    },
    {
        path: '/vrealtor',
        name: 'vrealtor',
        component: VistaVideoRealtorView
    },
    {
        path: '/overviewRealtor',
        name: 'overviewRealtor',
        component: RealtorsOverviewView
    },
    {
        path: '/overviewMlo',
        name: 'overviewMlo',
        component: MLOOverviewView
    },
    {
        path: '/faqs',
        name: 'faqs',
        component: FaqsViewVue
    },
    {
        path: '/loginusers',
        name: 'loginusersview',
        component: LoginUsersView
    },
    {
        path: '/register',
        name: 'registerusersview',
        component: RegisterUsersView,
        props: true
    },
    {
        path: '/register/payment',
        name: 'registeruserspaymentsview',
        component: RegisterUsersPaymentsView,
        props: true
    },
    {
        path: '/scope',
        name: 'realtorScope',
        component: RealtorScope,
        props: true
    },
    {
        path: '/admin/panel',
        name: 'adminview',
        component: AdminView,
        children: [
            {
                path: "",
                name: "admindashboardpanel",
                component: DashboardPanelView
            },
            {
                path: "contacts",
                name: "admincontactspanel",
                component: AdminContactsView
            },
            {
                path: "settings",
                name: "adminsettingspanel",
                component: SettingsPanelView
            },
            {
                path: "message",
                name: "realtormessages",
                component: MessageView
            },
            {
                path: "supportmessages",
                name: "supportmessages",
                component: SupportMessagesView
            },
            {
                path: "residential",
                name: "residential",
                component: ResidentialView
            },
            {
                path: "getloan",
                name: "getloan",
                component: GetLoanView
            },
            {
                path: "budget",
                name: "budget",
                component: BudgetView
            },
            {
                path: "mortgage-payment",
                name: "mortgage-payment",
                component: MortgagePaymentView
            },
            {
                path: "total-financial-monthly",
                name: "total-financial-monthly",
                component: TotalFinancialMonthlyPaymentView
            },
            {
                path: "total-cash-monthly",
                name: "total-cash-monthly",
                component: TotalCashMonthlyPaymentView
            },
            {
                path: "flip-property-ready",
                name: "flip-property-ready",
                component: FlipPropertyReadyView
            },
            {
                path: "flip-as-is",
                name: "flip-as-is",
                component: FlipAsIsView
            },
            {
                path: "pasive-financial",
                name: "pasive-financial",
                component: PasiveFinancialView
            },
            {
                path: "pasive-cash",
                name: "pasive-cash",
                component: PasiveCashView
            },
            {
                path: "short-term-cash",
                name: "short-term-cash",
                component: ShortTermCashView
            },
            {
                path: "short-term-financial",
                name: "short-term-financial",
                component: ShortTermFinancialView
            },
            {
                path: "foreign",
                name: "foreign",
                component: ForeignViewVue
            },
            {
                path: "realtordashboard",
                name: "realtordashboard",
                component: RealtorDashboard
            },
            
        ]
    },
    {
        path: "/contact/:role",
        name: "contactrelator",
        component: ContactRealtorView
    },
    {
        path: "/landing-mlo",
        name: "landingMLO",
        component: LandingMLO
    },
    {
        path: "/landing-title-company",
        name: "LandinTitleCompany",
        component: LandinTitleCompany
    },
    {
        path: "/landing-inspector",
        name: "LandingInspector",
        component: LandingInspector
    },
    {
        path: "/landing-realtors",
        name: "LandingRealtors",
        component: LandingRealtors
    },
    {
        path: "/realtor",
        name: "VideoRealtor",
        component: VideoRealtor
    },
    {
        path: "/about-us",
        name: "AboutView",
        component: AboutView
    },
   {
        path: "/checkout",
        name: "Checkout",
        component: Checkout
    },
    

]

const router = createRouter({
    //process.env.BASE_URL
    history: createWebHistory(),
    routes
})

export default router

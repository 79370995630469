
import UserService from '@/shared/services/user.service';
import UserTableService from '@/shared/services/usertable.service';
import Loading from 'vue-loading-overlay';
import { defineComponent, toRaw } from 'vue';
import ResidentialView from './ResidentialView.vue';
import useVuelidate from '@vuelidate/core';
import { required, email, numeric } from '@vuelidate/validators';
import Swal from 'sweetalert2';
import moment from 'moment';
import Vue3Html2pdf from 'vue3-html2pdf'

//Number format
import VueNumberFormat from 'vue-number-format'
import FormulaService from '@/shared/services/formula.service';

export default defineComponent({
    props: {
        foreign: null,
        header: null
    },
    data() {
        return {
            isCommentVisible: false,
            v$: useVuelidate(),
            userSession: null,
            userTables: [],
            scenarioType: null,
            scenario: {
                user: null,
                users: null,
                userScenarios: null,
                scenarioData: null
            },
            videoTutorial1: null,
            configDatePicker: {
                date: null
            },
            params: {
                scenario: {
                    users: {
                        name: "",
                        phonenumber: "",
                        from: "",
                        to: ""
                    },
                    scenarios: {
                        scenario_code: ""
                    }
                }
            },
            loaderConfig: {
                isLoading: true,
                fullPage: true
            },
            listStates: [],
            listCounties: [],

            listPropertyTypes: [],
            listTitleCompanies: [],
            additionalTitleCompanies: [],
            listInspectors: [],

            listUserSections: [],
            listTableProperties: [],

            selectedTitleCompany: null,
            financialInformation: {
                propertyState: null,
                propertyCounty: null,
                propertyAddresses: null,
                propertyZipCode: null,
                //
                propertyClosingCostBuy: null,
                propertyAuctionDates: null,
                propertyCaseId: null,
                //
                propertyClerkFees: null,
                propertyDocStamps: null,
                propertySearchTitleCompany: null,
                propertyAttorneyHours: null,
                propertyAttorney: null,
                propertyArrangment: null,
                propertyArragmentAmount: null,
                propertyOtherExpenses: null,
                propertyOtherExpensesAmount: null,
                //
                propertySellerAgentCommission: null,
                propertyBuyerAgentCommission: null,
                propertySellerClosingCost: null,
                propertyBrokerPayment: null,
                //
                propertyMonths: null,
                propertyAnnualPropertyTaxes: null,
                propertyMonthlyPropertyTaxes: null,
                propertyAnnualPropertyInsurance: null,
                propertyMonthlyPropertyInsurance: null,
                propertyAnnualStateTaxes: null,
                propertyMonthlyStateTaxes: null,
                propertyAssociationPayment: null,
                //
                propertyEstimateMarketValue: null,
                propertyFinalJudmentAmount: null,
                propertySellPrice: null,
                propertyBenefitNetProfitRequired: null,
                //
                // propertyOfferAmountBidBenefitNetProfit: null
                propertyBenefitNetProfit: null,
                propertyOfferAmountBid: null,
                propertyAssociationPaymentMonthly: null

            },
            customer: {
                name: null,
                phone: null,
                email: null
            },
            scenarioMail: {
                email: null,
                subject: null,
                message: null,
                file: null,
                fileConfig: null
            },
            userTitleCompany: {
                companyname: null,
                phonenumber: null,
                email: null
            }
        }
    },
    components: {
        Loading,
        ResidentialView,
        Vue3Html2pdf,
        VueNumberFormat //Component NumberFormat
    },
    validations: {
        customer: {
            name: { required },
            phone: { required, numeric },
            email: { required, email }
        },
        scenarioMail: {
            email: { required },
            subject: { required },
            message: { required },
        }
    },
    created() {
        this.init();
    },
    computed: {
        estimatedValue() {
            let value = 0;
            if (this.financialInformation.propertyEstimateMarketValue) {
                value = this.financialInformation.propertyEstimateMarketValue;
            }

            return value;
        },
        scenarioValid: function () {
            let k = true;
            if (this.v$.customer.$invalid) k = false;

            return k;
        },
        associationMonthlyPayment() {
            return this.financialInformation.propertyMonths * this.financialInformation.propertyAssociationPayment;
        },
        totalExpences() {
            let result = 0
            if (
                this.financialInformation.propertyClerkFees !== null &&
                this.financialInformation.propertyDocStamps !== null &&
                this.financialInformation.propertySearchTitleCompany !== null &&
                this.financialInformation.propertyAttorney !== null &&
                this.financialInformation.propertyArragmentAmount !== null &&
                this.financialInformation.propertyOtherExpensesAmount !== null &&
                this.financialInformation.propertySellerAgentCommission !== null &&
                this.financialInformation.propertySellerClosingCost !== null &&
                this.financialInformation.propertyBrokerPayment !== null &&
                this.financialInformation.propertyMonthlyPropertyTaxes !== null &&
                this.financialInformation.propertyMonthlyPropertyInsurance !== null &&
                this.financialInformation.propertyMonthlyStateTaxes !== null &&
                this.associationMonthlyPayment !== null
            ) {
                result = parseFloat(this.financialInformation.propertyClerkFees) +
                    parseFloat(this.financialInformation.propertyDocStamps) +
                    parseFloat(this.financialInformation.propertySearchTitleCompany) +
                    parseFloat(this.financialInformation.propertyAttorney) +
                    parseFloat(this.financialInformation.propertyArragmentAmount) +
                    parseFloat(this.financialInformation.propertyOtherExpensesAmount) +
                    parseFloat(this.financialInformation.propertySellerAgentCommission.text) +
                    parseFloat(this.financialInformation.propertySellerClosingCost.value) +
                    parseFloat(this.financialInformation.propertyBrokerPayment) +
                    parseFloat(this.financialInformation.propertyMonthlyPropertyTaxes) +
                    parseFloat(this.financialInformation.propertyMonthlyPropertyInsurance) +
                    parseFloat(this.financialInformation.propertyMonthlyStateTaxes) +
                    this.associationMonthlyPayment
            }

            return result
        },
        benefitNetProfit() {
            let result = 0
            if (this.financialInformation.propertySellPrice && this.financialInformation.propertyBenefitNetProfitRequired) {
                result = this.financialInformation.propertySellPrice * (this.financialInformation.propertyBenefitNetProfitRequired / 100)
            }
            return result
        },
        placeBid() {
            let result = 0
            if (this.financialInformation.propertySellPrice) {
                result = this.financialInformation.propertySellPrice - this.totalExpences - this.benefitNetProfit
            }
            return result
        }
    },
    methods: {
        toggleComment(){
            this.isCommentVisible = !this.isCommentVisible;

        },
        init: function () {
            this.getSession();
            this.loadSectionUsers();
            this.loadTables();
            this.getScenarioType(this.$route.name.toString().replaceAll("-", "_"));
            // this.loadTableTemplate("titlecompany_table");

            this.getStates();

            let _context = this;
            setTimeout(() => {

                // Property Price Change
                let el: any = document.getElementById("propertyEstimateMarketValue");
                el.addEventListener("change", () => {
                    console.log(_context.financialInformation.propertyEstimateMarketValue)
                    _context.calculateProperty('estimated');
                })

                let e: any = document.getElementById("propertyFinalJudmentAmount");
                e.addEventListener("change", () => {
                    console.log(_context.financialInformation.propertyFinalJudmentAmount)
                    _context.calculateProperty('clerkfees');
                })

                document.getElementById("propertySellPrice").oninput = () => {
                    this.calculateProperty("sellerprice");
                }


            }, 3000)
        },
        calculateMonthlyPayment() {
            this.financialInformation.propertyAssociationPaymentMonthly = this.associationMonthlyPayment;
        },
        changeRealtor: function ($event) {
            console.log($event);
            this.loadTables();
        },
        loadScenario: function (scenario) {
            if (scenario) {
                let scenariodata = scenario.data ? JSON.parse(scenario.data) : null;
                if (!scenariodata) return;

                delete scenario.data;
                this.scenario.scenarioData = { ...scenario, ...scenariodata };
                console.log(this.scenario.scenarioData);
            }
        },
        openModal: function (option: any) {
            let _window: any = window;
            let bootstrap = _window.bootstrap;

            this.videoTutorial1 = option; // Asigna el video directamente a videoTutorial1

            const modal = new bootstrap.Modal('#exampleModal3');
            modal.show();

            const modalopen = document.getElementById('exampleModal3');
            modalopen.addEventListener('hidden.bs.modal', event => {
                this.videoTutorial1 = null; // Limpia la variable del video al cerrar el modal
            });
        },
        changeDate: function () {
            if (this.configDatePicker.date) {
                this.params.scenario.users.from = moment(toRaw(this.configDatePicker.date)[0]).format("YYYY-MM-DD"),
                    this.params.scenario.users.to = moment(toRaw(this.configDatePicker.date)[1]).format("YYYY-MM-DD")
            }
            else {
                this.params.scenario.users.from = "";
                this.params.scenario.users.to = "";
            }
        },
        sendScenarioMail: async function ($event) {
            $event.target.disabled = true;
            this.loaderConfig.isLoading = true;
            let formdata = new FormData();
            let files: any[] = [this.scenarioMail.file];
            formdata.append("name", this.scenarioMail.email);
            formdata.append("to", this.scenarioMail.email);
            formdata.append("subject", this.scenarioMail.subject);
            formdata.append("message", this.scenarioMail.message);
            files.forEach((a, w) => {
                formdata.append("files[]", a);
            })

            const response = (await UserService.sendUserMail(formdata)).data;

            if (!response.error) {

                for (let w in this.scenarioMail) {
                    this.scenarioMail[w] = null;
                }

                Swal.fire({
                    title: "Message",
                    text: "Mail sended!",
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                })
            }
            else {
                console.log(response);
            }
            $event.target.disabled = false;
            this.loaderConfig.isLoading = false;
        },
        generateMail: function () {
            let ref: any = this.$refs.html2Pdf;
            ref.generatePdf();
        },
        commaFormat(value) {
            if (!value) return '';
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        beforeFileGenerated: function (event) {
            this.scenarioMail.fileConfig = event;
            // this.scenarioMail.email = this.scenario.scenarioData.user.user_email;
            document.querySelector(".content-wrapper").innerHTML = document.getElementById("printable-target").innerHTML;
            document.querySelector(".content-wrapper").classList.add("tw-p-10");
            this.scenarioMail.fileConfig.html2pdf().from(this.scenarioMail.fileConfig.pdfContent).toPdf().output('blob').then((data: any) => {
                let file = new File([data], this.$route.name.toString().split("-").map(a => a[0].toUpperCase()).toString().replaceAll(",", "") + "_" + this.scenario.scenarioData.user.user_name.toUpperCase() + "_" + moment().format("MMDDYYYY") + ".pdf", { type: 'application/pdf' });
                this.scenarioMail.file = file;
                let w: any = window;
                w.modalclose("#" + document.querySelectorAll("div .modal[id*=exampleModalEscenario]")[0].id);
                w.openModal("exampleModalMessage");

                console.log(this.scenarioMail);
            })
        },
        printScenario: function () {
            document.getElementById("printable-template").innerHTML = document.getElementById("printable-target").innerHTML;
            window.print();
        },
        addUserExtraTables: async function () {
            let params: any = {
                user: this.userSession.iduser,
                tabletype: ["mlo_primary", "mlo_second", "mlo_investment", "mlo_special", "titlecompany_table", "usertable_mlo", "usertable_titlecompany"]
            }

            params.tabletype = params.tabletype.toString();

            const response = (await UserTableService.getTables(params)).data

            if (!response.error) {
                let data = response.data;
                let datatable = data.map(a => { return { ...a, tabledata: UserTableService.convertTable(JSON.parse(a.tabledata)), id_tabletype: btoa(a.tabletype) } });

                let users = [...data.filter(a => a.tabletype.includes("usertable"))];

                users.forEach((u, z) => {

                    if (u.tabletype.includes("titlecompany")) {
                        u.tables = datatable.filter(ut => !ut.tabletype.includes("usertable") && ut.tabletype.includes("titlecompany"))
                    }

                    let user = { ...u, ...JSON.parse(u.tabledata) }
                    delete user.tabledata;

                    if (u.tabletype.includes("titlecompany")) this.listTitleCompanies.push(user);
                });



                this.listTableProperties = this.listTableProperties.filter(a => !["investment", "foreign"].includes(a.name.toLowerCase()))
                console.log(this.listTitleCompanies);
            }
            else {
                this.$toast.error("Error loading tables", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getStates: async function () {
            this.loaderConfig.isLoading = true
            const response = (await UserService.getZoneStates()).data

            if (!response.error) {
                let list: any = toRaw(response.data)
                this.listStates = list
            }
            else {
                this.$toast.error("Error loading states", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false
        },
        getCounties: async function () {
            this.loaderConfig.isLoading = true;
            let state: any = this.financialInformation.propertyState;
            let param = state == 0 ? state.toString() : state;
            const response = (await UserService.getCounty(param)).data

            if (!response.error) {
                let list: any = toRaw(response.data)
                if (list.length > 0) {
                    this.listCounties = list.filter((a: any) => a.length > 0);
                };
            }
            else {
                this.$toast.error("Error loading countries", { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false
        },
        addTitleCompanyTable: function () {
            let title = this.listTitleCompanies.findIndex(a => a.added);
            if (title >= 0) {
                this.listTitleCompanies[title] = this.additionalTitleCompanies[0];
            }
            else {
                this.listTitleCompanies.push(this.additionalTitleCompanies[0]);
            }

            Swal.fire({
                title: "Message",
                text: "Saved",
                icon: "success",
                timer: 3000,
                timerProgressBar: false
            });
        },
        loadTableTemplate: async function (type) {
            this.loaderConfig.isLoading = true;

            const params = { type: type }
            const response = (await UserTableService.getTemplate(params)).data;

            if (!response.error) {
                let tables = UserTableService.convertTable(JSON.parse(response.data[0].tabledata));

                if (type == 'titlecompany_table' && this.additionalTitleCompanies.length == 0) {
                    let o: any = { ...this.userTitleCompany, added: true }

                    o.tables = [
                        {
                            tabletype: type,
                            tabledata: tables
                        }
                    ]

                    this.additionalTitleCompanies.push(o);
                }

            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        loadScenarios: async function (user) {
            this.loaderConfig.isLoading = true

            const params = { createdby: this.userSession.iduser, user: user.scenario_user_id, scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarios(params)).data;

            if (!response.error) {
                this.scenario.userScenarios = response.data;
                if (this.scenario.userScenarios.results.length > 0) {
                    this.scenario.userScenarios.results = this.scenario.userScenarios.results.map(a => { return { ...a, date: moment(a.date).format("DD/MM/YYYY"), type_name: a.type_name.replaceAll("_", " ") } });
                }
                else {
                    this.scenario.userScenarios = [];
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getScenarioUsers: async function () {
            this.loaderConfig.isLoading = true

            const params = { ...this.params.scenario.users, scenario: this.scenarioType.scenario_type_id }
            const response = (await UserService.getScenarioUsers(params)).data;

            console.log(params);

            if (!response.error) {
                this.scenario.users = response.data;
                if (this.scenario.users.results.length > 0) {
                    this.scenario.users.results = this.scenario.users.results.map(a => { return { ...a, create_date: moment(a.create_date).format("DD/MM/YYYY") } })
                }
            }
            else {
                this.$toast.error(response.message, { position: 'top-right' })
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getScenarioType: async function (name) {
            this.loaderConfig.isLoading = true

            // if (this.foreign) name = this.foreign;

            const params = { name: this.foreign ? "foreign_" + this.foreign : name }
            const response = await UserService.getScenarioTypes(params);

            if (!response.data.error) {
                if (response.data.data.length > 0) {
                    this.scenarioType = response.data.data[0];
                    this.getScenarioUsers();
                }
            }
            else {
                this.$toast.error(response.data.message, { position: 'top-right' })
                console.log(response.data.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getSession: function () {
            let session = localStorage.getItem("usersession") && localStorage.getItem("usersession").length > 0 ? JSON.parse(localStorage.getItem("usersession")) : null

            if (session) {
                this.userSession = session
            } else {
                UserService.logout("Session expired!")
            }
        },
        loadTables: async function () {
            this.loaderConfig.isLoading = true
            let tabletype = `${this.userSession.role.replaceAll(" ", "").toLowerCase()}_table`

            let usertables: any[] = [];

            let q = {
                user: this.userSession.id_user ? this.userSession.id_user : this.userSession.iduser,
                tabletype: tabletype
            }

            const response = (await UserTableService.getTables(q)).data
            if (!response.error) {
                let responsedata = response.data.length > 0 ? response.data[0] : null;

                if (responsedata) {
                    let tabledata = JSON.parse(responsedata.tabledata);

                    usertables = UserTableService.convertTable(tabledata);

                    const auctions = usertables.find(a => a.id == '2');
                    const comissions = usertables.find(a => a.id == '5');
                    const taxesandinsurance = usertables.find(a => a.id == '4').data;
                    console.log(taxesandinsurance);

                    // auctions.recalculate = () => {
                    //     auctions.data.forEach((b: any) => {
                    //         if (b.table_name == "CLERCK FEE" && this.financialInformation.propertyFinalJudmentAmount) {
                    //             this.financialInformation.propertyClerkFees = (parseFloat(b.table_text) * (this.financialInformation.propertyFinalJudmentAmount ? this.financialInformation.propertyFinalJudmentAmount:1)).toFixed(2);
                    //         }

                    //         if (b.table_name == "DOC STAMP" && this.financialInformation.propertyFinalJudmentAmount) {
                    //             this.financialInformation.propertyDocStamps = (parseFloat(b.table_text) * (this.financialInformation.propertyFinalJudmentAmount ? this.financialInformation.propertyFinalJudmentAmount:1)).toFixed(2);
                    //         }
                    //     })
                    // }
                    auctions.recalculate = () => {
                        auctions.data.forEach((b: any) => {
                            if (b.table_name == "CLERCK FEE" && this.financialInformation.propertyFinalJudmentAmount) {
                                this.financialInformation.propertyClerkFees = (((parseFloat(b.table_text) / 100) * (this.financialInformation.propertyFinalJudmentAmount ? this.financialInformation.propertyFinalJudmentAmount : 1))).toFixed(0);
                            }

                            if (b.table_name == "DOC STAMP" && this.financialInformation.propertyFinalJudmentAmount) {
                                this.financialInformation.propertyDocStamps = (((parseFloat(b.table_text) / 100) * (this.financialInformation.propertyFinalJudmentAmount ? this.financialInformation.propertyFinalJudmentAmount : 1))).toFixed(0);
                            }
                        });
                    };

                    // taxesandinsurance.forEach((b: any) => {
                    //     if (b.table_name == "PROPERTY INSURANCE") {
                    //         this.financialInformation.propertyAnnualPropertyInsurance = b.table_text;
                    //     }
                    // })

                    comissions.recalculate = (c: boolean = false) => {
                        comissions.data.forEach((b: any) => {
                            if (b.table_name == "SELLER AGENTS COMMISSION" && !c) {
                                this.financialInformation.propertySellerAgentCommission = { text: null, value: b.table_text / 100 };
                                this.financialInformation.propertySellerAgentCommission.text = this.financialInformation.propertySellerAgentCommission.value * this.financialInformation.propertySellPrice;
                            }

                            if (b.table_name == "BUYER AGENTS COMMISSION" && !c) {
                                this.financialInformation.propertyBuyerAgentCommission = { text: null, value: b.table_text / 100 };
                                this.financialInformation.propertyBuyerAgentCommission.text = this.financialInformation.propertyBuyerAgentCommission.value * this.financialInformation.propertySellPrice;
                            }

                            if (b.table_name == "REAL ESTATE BROKER FEE" && c) {
                                this.financialInformation.propertyBrokerPayment = b.table_text;
                            }
                        })
                    }

                    auctions.recalculate();
                    comissions.recalculate(true);


                    this.userTables = usertables;
                    console.log(usertables);

                }
            }
            this.loaderConfig.isLoading = false;
        },
        calculateProperty: function (type: string) {
            if (type == "attorney") {
                let lawyerPrice = this.userTables.find(a => a.id == '2').data.find((a: any) => a.table_name == "LAWYER HOURLY PRICE")?.table_text;
                if (lawyerPrice) {
                    this.financialInformation.propertyAttorney = parseFloat(this.financialInformation.propertyAttorneyHours ? this.financialInformation.propertyAttorneyHours : 0) * parseFloat(lawyerPrice);
                }
                else {
                    this.financialInformation.propertyAttorney = 0;
                }
            }

            if (type == "arragment" || type == "estimated") {
                if (this.financialInformation.propertySellPrice && this.financialInformation.propertyArrangment) {
                    this.financialInformation.propertyArragmentAmount = parseFloat(this.financialInformation.propertySellPrice) * (parseFloat(this.financialInformation.propertyArrangment) / 100);
                }
            }

            // if (type == "otherexpenses" || type == "estimated") {
            //     this.financialInformation.propertyOtherExpensesAmount = parseFloat(this.financialInformation.propertyOtherExpenses ? this.financialInformation.propertyOtherExpenses : 0) * this.estimatedValue
            // }

            if (type == "monthlytaxes") {
                this.financialInformation.propertyMonthlyPropertyTaxes = ((parseFloat(this.financialInformation.propertyAnnualPropertyTaxes ? this.financialInformation.propertyAnnualPropertyTaxes : 0) / 12) * parseFloat(this.financialInformation.propertyMonths ? this.financialInformation.propertyMonths : 0)).toFixed(0)
            }

            if (type == "monthlystatetaxes" || type == "monthlytaxes") {
                this.financialInformation.propertyMonthlyStateTaxes = ((parseFloat(this.financialInformation.propertyAnnualStateTaxes ? this.financialInformation.propertyAnnualStateTaxes : 0) / 12) * parseFloat(this.financialInformation.propertyMonths ? this.financialInformation.propertyMonths : 0)).toFixed(0)
            }

            if (type == "estimated" || type == "monthlytaxes") {
                let comissions = this.userTables.find(a => a.id == '5');
                this.financialInformation.propertyMonthlyPropertyInsurance = (((this.financialInformation.propertyAnnualPropertyInsurance ? this.financialInformation.propertyAnnualPropertyInsurance : 0) / 12) * parseFloat(this.financialInformation.propertyMonths ? this.financialInformation.propertyMonths : 0)).toFixed(0)
                comissions.recalculate();
            }

            if (type == "sellerprice") {
                let comissions = this.userTables.find(a => a.id == '5');
                comissions.recalculate();
                if (this.selectedTitleCompany) this.financialInformation.propertySellerClosingCost.calculate();
            }

            if (type == "clerkfees") {
                const auctions = this.userTables.find(a => a.id == '2');
                auctions.recalculate();
            }
        },
        loadSectionUsers: async function () {
            this.loaderConfig.isLoading = true;
            let params = {
                zipcode: this.userSession?.zipcode
            }

            let response = (await UserService.getSectionUsers(params)).data;

            if (!response.error) {
                this.listTitleCompanies = toRaw(response.data.titlecompany_users);
                this.listTitleCompanies = this.listTitleCompanies.map((w) => {
                    if (w.tables) {
                        w.tables = w.tables.map((a: any) => {
                            a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                            return a;
                        })
                    }

                    return toRaw(w);
                })

                this.listInspectors = toRaw(response.data.inspector_users);
                this.listInspectors = this.listInspectors.map((w) => {
                    if (w.tables) {
                        w.tables = w.tables.map((a: any) => {
                            a.tabledata = UserTableService.convertTable(JSON.parse(a.tabledata));
                            return a;
                        })
                    }

                    return toRaw(w);
                })

                this.addUserExtraTables();
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
        },
        getItTitleCompany: function (user: any) {
            this.selectedTitleCompany = user;
            console.log(this.selectedTitleCompany)
            const table = this.selectedTitleCompany.tables.find((a: any) => a.tabletype == "titlecompany_table").tabledata;
            const closingconsts = table.find((e: any) => e.id == "1").data;
            const otherprices = table.find((e: any) => e.id == "2").data;
            closingconsts.forEach((a: any) => {
                if (a.table_name3 == "BUYER") {
                    this.financialInformation.propertyClosingCostBuy = a.table_closing_close;
                }

                if (a.table_name3 == "SELLER") {
                    this.financialInformation.propertySellerClosingCost = { value: null, text: parseFloat(a.table_closing_close) / 100 };
                    this.financialInformation.propertySellerClosingCost.calculate = () => {
                        this.financialInformation.propertySellerClosingCost.value = this.financialInformation.propertySellerClosingCost.text * this.financialInformation.propertySellPrice;
                    }

                    if (this.financialInformation.propertySellPrice) this.financialInformation.propertySellerClosingCost.calculate();
                }
            })

            otherprices.forEach((a: any) => {
                if (a.table_name.includes("SEARCH")) {
                    this.financialInformation.propertySearchTitleCompany = a.table_each_tittle_review;
                }
            })

            Swal.fire({
                title: "Title Company",
                text: this.selectedTitleCompany.companyname + " selected",
                icon: "success",
                timer: 3500,
                timerProgressBar: true,
                showConfirmButton: false,
                showCloseButton: false,
                showCancelButton: false
            })

        },
        getObject: function (values: any[]) {
            let object: any = {};
            values.forEach((el: any) => {
                object[el.name] = el.value;
            });

            return object;
        },
        submitScenario: async function ($event) {
            this.loaderConfig.isLoading = true;

            $event.disabled = true;

            let px = this.$route.name;

            if (this.foreign) {
                px = "foreign-" + this.foreign.replaceAll("_", "-");
            }

            let params: any = {
                code: px.toString().split("-").map(a => a[0].toUpperCase()).toString().replaceAll(",", "") + "-" + btoa(Date.now().toString()).toUpperCase().replaceAll("=", ""),
                date: moment(new Date()).format("YYYY-MM-DD"),
                type: this.scenarioType.scenario_type_id,
                user_email: this.customer.email,
                user_phonenumber: this.customer.phone,
                user_name: this.customer.name,
                data: {
                    financial_information: { ...this.financialInformation },
                },
                id_user: this.userSession.iduser
            }

            if (this.foreign) {
                params.data.scenario_type = this.foreign;
            }

            params.data = JSON.stringify(params.data);

            let response = (await UserService.saveScenario(params)).data;

            if (!response.error) {
                Swal.fire({
                    title: "Realtor",
                    text: response.message,
                    icon: "success",
                    timer: 3500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: false,
                    showCancelButton: false
                }).then(() => {
                    window.location.reload();
                })
            }
            else {
                console.log(response.message)
            }

            this.loaderConfig.isLoading = false;
            $event.disabled = false;
        }
    }
})

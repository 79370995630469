<template>
  <loading v-model:active="loaderConfig.isLoading" :can-cancel="false" :loader="'dots'"
    :is-full-page="loaderConfig.fullPage" />
  <div class="hidden-mobile">
    <div class="sign-up">
      <div class="container-fixed">
        <div class="sign-up__content d-flex justify-content-between">
          <div class="w-50">
            <!-- Mostrar imagen según el valor de type -->
            <div v-if="$route.query.type == 1" class="sign-up_content_imagen p-0 h-100 w-100">
              <img src="../../assets/Images/img-realtor.png" class="w-100 p-0 h-100" alt="Realtor" />
            </div>
            <div v-if="$route.query.type == 3" class="sign-up_content_imagen h-100 w-100">
              <img src="../../assets/Images/inspector.png" class="w-100 p-0 h-100" alt="Inspector" />
            </div>
            <div v-if="$route.query.type == 5" class="sign-up_content_imagen h-100 w-100">
              <img src="../../assets/Images/img-title.png" class="w-100 p-0 h-100" alt="Title Company" />
            </div>
            <div v-if="$route.query.type == 2" class="sign-up_content_imagen h-100 w-100">
              <img src="../../assets/Images/mlo.png" class="w-100 p-0 h-100" alt="MLO" />
            </div>

          </div>
          <div class="sign-up_content_formulario pr-0 w-50 d-flex justify-content-center align-items-center">
            <form class="w-50">


              <div class="sign-up_contentformulario_img mb-4">
                <div class="sign-up_contentformularioimg_return text-left">
                  <a class="d-flex align-items-center" href="/">
                    <i class="fas fa-arrow-circle-left"></i>
                    <span> Go back </span>
                  </a>
                </div>
              </div>
              <img src="../../assets/Images/logo-login.png" class=" mx-auto mb-5" style="width: 30%;"/>
              <section v-if="$route.query.type == 1" class="t-1 mb-5">
                <h2 class="display-6 text-center fw-bold mb-3">Realtor Subscription</h2>
                <p class=" mb-3">Subscribe to Realtor and enjoy the benefits of our exclusive services.</p>
                <p class="h4 text-center fw-bold">30$ monthly</p>
            </section>
            <section v-if="$route.query.type == 2" class="t-2 mb-5">
              <h2 class="display-6 text-center fw-bold mb-3">MLO Subscription</h2>
              <p class=" mb-3">Subscribe to MLO and enjoy the benefits of our exclusive services.</p>
              <p class="h4 text-center fw-bold">30$ monthly</p>
            </section>
            <section v-if="$route.query.type == 3" class="t-3 mb-5">
              <h2 class="display-6 text-center fw-bold mb-3">Inspector Subscription</h2>
              <p class=" mb-3">Subscribe to Inspector and enjoy the benefits of our exclusive services.</p>
              <p class="h4 text-center fw-bold">20$ monthly</p>
            </section>
            <section v-if="$route.query.type == 5" class="t-6 mb-5">
              <h2 class="display-6 text-center fw-bold mb-3">Title Company Subscription</h2>
              <p class=" mb-3">Subscribe to Title Company and enjoy the benefits of our exclusive services.</p>
              <p class="h4 text-center fw-bold">30$ monthly</p>
            </section>
   
              <section class="t-5">
                <Stripe 
                  :idUsuario="idUsuario"
                  :roleType="roleType"
                  @payment-success="handlePaymentSuccess" 
                />
              </section>

            
              <div class="sign-up_contentformulario_form-register d-flex justify-content-center"></div>

            </form>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>



<script>



export default {

  data() {
    return {
      loaderConfig: {
        isLoading: false,
        fullPage: true,
      },
      idUsuario: null,
      roleType: null,
    };
  },
  mounted() {
    this.loaderConfig.isLoading = true;
    this.idUsuario = this.$route.query.userId;
    this.roleType = this.$route.query.type;
    this.loaderConfig.isLoading = false;
  },
  methods: {
    handlePaymentSuccess() {
      this.$router.push('/login');
    },
  },
};
</script>

import UserService from "@/shared/services/user.service";
import { defineComponent, toRaw } from "vue";
import Loading from "vue-loading-overlay";
import moment from "moment";
import { environment } from "@/environment";
import UserTableService from "@/shared/services/usertable.service";
import Swal from "sweetalert2";

//Number format
import VueNumberFormat from "vue-number-format";

import { vMaska } from "maska";

export default defineComponent({
  props: {
    header: null,
  },
  directives: { maska: vMaska },
  data() {
    return {
      isCommentVisible:false,
      noteModal: false,
      userSession: null,
      userSection: null,
      _window: null,
      tableUpdated: false,
      URL: environment.URL_UPLOADS,
      tables: [],
      listUserSections: [],
      listMails: [],
      listSortedMails: [],
      selectedRole: null,
      selectedUser: null,
      selectedMail: null,
      paramsTable: null,
      videoTutorial1: null,
      filterMails: {
        date: null,
        read: -1,
        state: -1,
      },
      supportMessage: {
        iduser: null,
        message: null,
      },
      configDatePicker: {
        date: null,
      },
      loaderConfig: {
        isLoading: true,
        fullPage: true,
      },
      selectedTitleCompany: null,
      listTitleCompanies: [],
      listInspectors: [],
      additionalTitleCompanies: [],
      inspectionTypes: [
        {
          id: 0,
          name: "ATTIC",
        },
        {
          id: 1,
          name: "ASBESTOS",
        },
        {
          id: 2,
          name: "ELECTRIC",
        },
        {
          id: 3,
          name: "RADON",
        },
        {
          id: 4,
          name: "ROOF INSPECTION",
        },
        {
          id: 5,
          name: "POINT INSPECTION",
        },
        {
          id: 6,
          name: "FOUNDATION INSPECTION",
        },
        {
          id: 7,
          name: "HVAC",
        },
        {
          id: 8,
          name: "MOLD INSPECTION",
        },
        {
          id: 9,
          name: "POOL INSPECTION",
        },
        {
          id: 10,
          name: "HEATING SYSTEM",
        },
        {
          id: 11,
          name: "LEAD",
        },
        {
          id: 12,
          name: "PLUMBINO",
        },
        {
          id: 13,
          name: "WDO INSPECTION",
        },
      ],
      userTitleCompany: {
        companyname: null,
        phonenumber: null,
        email: null,
      },
      customer: {
        name: null,
        phone: null,
        email: null,
      },
      userTables: [],
      titleTable: "Primary",
      tableTypesMLO: [
        {
          key: "mlo_primary",
          name: "Primary",
          visible: true,
          tables: [],
        },
        {
          key: "mlo_second",
          name: "Second",
          visible: false,
          tables: [],
        },
        {
          key: "mlo_investment",
          name: "Investment",
          visible: false,
          tables: [],
        },
        {
          key: "mlo_special",
          name: "Special",
          visible: false,
          tables: [],
        },
        {
          key: "mlo_foreign",
          name: "Foreign",
          visible: false,
          tables: [],
        },
      ],
      tableTypesTitleCompany: [
        {
          key: "titlecompany_table",
          name: "Title Company",
          visible: true,
          tables: [],
        },
      ],
    };
  },
  components: {
    Loading,
    VueNumberFormat, //Component NumberFormat
  },
  created() {
    this.init();
  },
  methods: {
    toggleComment(){
            this.isCommentVisible = !this.isCommentVisible;

        },
    init: function () {
      this.getSession();

      this._window = window;

      this.loadSectionUsers();
      this.loadExtraTables();
      // if (this._window.getTables == null) {

      // }else { console.log("123") }
    },
    submitReport: async function (event) {
      this.loaderConfig.isLoading = true;
      event.target.disabled = true;
      let params: any = {
        ...this.supportMessage,
        iduser: this.userSession.iduser,
      };

      const response = (await UserService.registerSupportMessage(params)).data;

      if (!response.error) {
        this.supportMessage.iduser = null;
        this.supportMessage.message = null;
        Swal.fire({
          position: "center",
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 1500,
        });

        let w: any = window;
        w.modalclose("#exampleModalSupport");
      } else {
        this.$toast.error("Error: " + response.message, {
          position: "top-right",
        });
        console.log(response.message);
      }

      this.loaderConfig.isLoading = false;
      event.target.disabled = false;
    },
    loadInspectorUser: async function (type) {
      this.loaderConfig.isLoading = true;
      let params = {
        inspectiontype: type,
      };

      let response = (await UserService.getUsers(params)).data;

      if (!response.error) {
        this.listInspectors = toRaw(response.data).results;
        // console.log(this.userSection);
      } else {
        console.log(response.message);
      }

      this.loaderConfig.isLoading = false;
    },
    openModal: function (option: any) {
      let _window: any = window;
      let bootstrap = _window.bootstrap;

      this.videoTutorial1 = option; // Asigna el video directamente a videoTutorial1

      const modal = new bootstrap.Modal("#exampleModal3");
      modal.show();

      const modalopen = document.getElementById("exampleModal3");
      modalopen.addEventListener("hidden.bs.modal", (event) => {
        this.videoTutorial1 = null; // Limpia la variable del video al cerrar el modal
      });
    },
    changeTableValue: function (t, event, target) {
      t[target] = parseInt(event.target.value.replaceAll(",", ""));
    },
    changeTableType: function (type) {
      if (type.includes("mlo")) {
        this.tableTypesMLO.forEach((a) => {
          a.visible = false;

          if (a.key == type) {
            this.titleTable = a.name;
            a.visible = true;
          }
        });
      } else {
        this.tableTypesTitleCompany.forEach((a) => {
          a.visible = false;

          if (a.key == type) {
            a.visible = true;
          }
        });
      }
    },
    submitTable: async function () {
      this.loaderConfig.isLoading = true;
      const tabletype =
        this.userSession.role.replaceAll(" ", "").toLowerCase() + "_table";

      let paramsTable = {
        ...this.paramsTable,
        tabletype: tabletype,
        iduser: this.userSession.id_user
          ? this.userSession.id_user
          : this.userSession.iduser,
        tabledata: JSON.stringify(toRaw(this.tables)),
      };

      const response = await UserTableService.register(toRaw(paramsTable));

      if (!response.data.error) {
        console.log(response.data);

        this.$emit("realtorEvent");

        // this.$toast.success("Tables saved!", { position: 'top-right' })
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Data saved successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$toast.error("Error!", { position: "top-right" });
        console.log(response.data.message);
      }
      this.loaderConfig.isLoading = false;
    },
    // getItTitleCompany: function(user: any) {
    //     this.selectedTitleCompany = user;
    //     this.shortTermCash.propertyClosingCostBuy = this.selectedTitleCompany.tables.find((a: any) => a.tabletype == "titlecompany_table").tabledata.find((e: any) => e.id == "1").data.find((j: any) => j.table_name == "CLOSING CLOSE").table_closing_close;
    // },
    getSession: function () {
      let session =
        localStorage.getItem("usersession") &&
        localStorage.getItem("usersession").length > 0
          ? JSON.parse(localStorage.getItem("usersession"))
          : null;

      if (session) {
        this.userSession = session;
      } else {
        UserService.logout("Session expired!");
      }
    },
    modalListUsers: function (t: any) {
      if (this.userSection) {
        this.selectedRole = t;

        if (t == 1) {
          this.listUserSections = this.userSection.mlo_users;
        } else if (t == 2) {
          this.listUserSections = this.userSection.inspector_users;
        } else {
          this.listUserSections = this.userSection.titlecompany_users;
        }
      }
    },
    loadSectionUsers: async function () {
      this.loaderConfig.isLoading = true;
      let params = {
        zipcode: this.userSession?.zipcode,
      };

      let response = (await UserService.getSectionUsers(params)).data;

      if (!response.error) {
        this.userSection = toRaw(response.data);
        // console.log(this.userSection);
      } else {
        console.log(response.message);
      }

      this.loaderConfig.isLoading = false;
    },
    saveExtraTables: async function (type, update: boolean = false) {
      this.loaderConfig.isLoading = true;

      if (!update)
        this.$toast.warning("This action may take few minutes!", {
          position: "top-right",
          duration: 5000,
        });

      let errors = {
        error: false,
        message: null,
      };

      let tableTypes: any[];

      if (type == "mlo") {
        tableTypes = this.tableTypesMLO;
      } else {
        tableTypes = this.tableTypesTitleCompany;
      }

      let usertable = tableTypes.find((a) => a.key.includes("usertable"));
      if (!usertable) {
        tableTypes.push({
          ...this.userTables
            .map((a) => {
              return { ...a, tables: a.data };
            })
            .find((a) => a.type == type),
        });
      } else {
        tableTypes.forEach((a) => {
          if (a.key.includes("usertable")) {
            a.tables = a.data;
          }
        });
      }

      for (let w in tableTypes) {
        let paramsTable = {
          ...this.paramsTable,
          tableid: tableTypes[w].id,
          tabletype: tableTypes[w].key,
          iduser: tableTypes[w].user,
          tabledata: JSON.stringify(toRaw(tableTypes[w].tables)),
        };

        const response = (await UserTableService.register(toRaw(paramsTable)))
          .data;
        if (response.error) {
          errors.error = true;
          errors.message = response.message;
        }
      }

      if (!errors.error) {
        // this.$toast.success("Tables saved!", { position: 'top-right' })
        if (!update) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Data saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }

        // this.loadExtraTables();
        if (!update) window.location.reload();
        if (update) {
          this.tableUpdated = true;
          this.loadExtraTables();
        }
      } else {
        this.$toast.error("Error!", { position: "top-right" });
        console.log(errors.message);
      }

      this.loaderConfig.isLoading = false;
    },
    loadExtraTables: async function () {
      let params: any = {
        user: this.userSession.iduser,
        tabletype: [
          "mlo_primary",
          "mlo_second",
          "mlo_investment",
          "mlo_special",
          "titlecompany_table",
          "usertable_mlo",
          "usertable_titlecompany",
          "mlo_foreign",
        ],
      };

      params.tabletype = params.tabletype.toString();

      const response = (await UserTableService.getTables(params)).data;

      if (!response.error) {
        this.userTables = [];
        let data = response.data;
        let datatable = data.map((a) => {
          return {
            ...a,
            tabledata: UserTableService.convertTable(JSON.parse(a.tabledata)),
            id_tabletype: btoa(a.tabletype),
          };
        });

        this.tableTypesTitleCompany = this.tableTypesTitleCompany.map(
          (a: any) => {
            let tables = datatable.find((b) => b.tabletype == a.key);
            if (tables) {
              a.tables = tables.tabledata;
            }
            return { ...a, id: tables.tableid, user: tables.iduser };
          }
        );

        this.tableTypesMLO = this.tableTypesMLO.map((a: any) => {
          let tables = datatable.find((b) => b.tabletype == a.key);
          if (tables) {
            a.tables = tables.tabledata;
          }
          return {
            ...a,
            id: tables.tableid,
            user: tables.iduser,
            outdated: tables.outdated,
          };
        });

        data
          .filter((a) => a.tabletype.includes("usertable"))
          .forEach((j) => {
            this.userTables.push({
              id: j.tableid,
              user: j.iduser,
              type: j.tabletype.split("_")[1],
              key: j.tabletype,
              data: { ...JSON.parse(j.tabledata), iduser: j.iduser },
            });
          });

        if (
          this.tableTypesMLO.filter((a: any) => a.outdated).length > 0 &&
          !this.tableUpdated
        ) {
          this.saveExtraTables("mlo", true);
        }
      } else {
        this.$toast.error("Error loading tables", { position: "top-right" });
        console.log(response.message);
      }

      this.loaderConfig.isLoading = false;
    },
    loadTable: async function (
      user: any,
      type: any = 0,
      realtor: boolean = false
    ) {
      let tabletype = null;

      if (user) {
        this.selectedUser = user;
      }

      if (realtor) {
        this.selectedUser = this.userSession;
      }

      tabletype =
        this.selectedUser.tipo_rol.replaceAll(" ", "").toLowerCase() + "_table";

      if (this.selectedUser.tipo_rol == "MLO") {
        if (type == 0) {
          tabletype = this.selectedUser.tipo_rol.toLowerCase() + "_primary";
        } else {
          tabletype = type;
        }
      }

      this.loaderConfig.isLoading = true;

      let q = {
        user: this.selectedUser.iduser,
        tabletype: tabletype,
      };

      const response = (await UserTableService.getTables(q)).data;

      if (!response.error) {
        this.paramsTable = response.data ? response.data[0] : null;

        if (this.paramsTable) {
          let tabledata = JSON.parse(this.paramsTable.tabledata);

          if (tabledata && tabledata.length > 0) {
            this.tables = UserTableService.convertTable(tabledata);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Empty tables",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      } else {
        this.$toast.error("Error loading tables", { position: "top-right" });
        console.log(response.message);
      }

      this.loaderConfig.isLoading = false;
    },
    selectMail: async function (mail: any) {
      this.selectedMail = mail;
    },
    updateMail: async function (mail: any) {
      this.loaderConfig.isLoading = true;
      let params = new FormData();
      params.append("idmail", mail.idmail);
      params.append("mailfrom", mail.mailfrom);
      params.append("mailto", mail.mailto);
      params.append("subject", mail.subject);
      params.append("message", mail.message);
      params.append("mailopen", mail.mailopen);
      params.append("maildone", mail.maildone ? "1" : "0");
      params.append("mailpending", mail.mailpending ? "1" : "0");

      let response = (await UserService.updateMail(params)).data;

      if (!response.error) {
        this.loadMessages();
      } else {
        console.log(response.message);
      }
    },
    filterMailState: function () {
      if (this.filterMails.state == 0) {
        this.listSortedMails = this.listMails.filter((a) => a.maildone);
      } else if (this.filterMails.state == 1) {
        this.listSortedMails = this.listMails.filter((a) => a.mailpending);
      } else {
        this.listSortedMails = this.listMails;
      }
    },
    changeDate: function () {
      if (this.configDatePicker.date) {
        this.filterMails.date = {
          date1: moment(toRaw(this.configDatePicker.date)[0]).format(
            "YYYY-MM-DD"
          ),
          date2: moment(toRaw(this.configDatePicker.date)[1]).format(
            "YYYY-MM-DD"
          ),
        };
      } else {
        this.filterMails.date = null;
      }

      this.loadMessages();
    },
    loadMessages: async function (datos: any = null) {
      this.loaderConfig.isLoading = true;

      if (datos) {
        this.selectedUser = datos;
      }

      let params = {
        ...this.filterMails,
        email: this.selectedUser.email,
        type: this.selectedUser.role == "Realtor" ? "from" : "to",
      };

      let response = (await UserService.getMails(params)).data;

      if (!response.error) {
        this.listSortedMails = toRaw(response.data);
        this.listSortedMails = this.listSortedMails.map((e) => {
          return {
            ...e,
            selected: false,
            date1: e.maildate ? moment(e.maildate).format("MM/DD/YYYY") : null,
            date2: e.maildate ? moment(e.maildate).format("MMMM DD") : null,
            inspectiondate: e.inspection_date
              ? moment(e.inspection_date).format("MM/DD/YYYY")
              : null,
          };
        });

        this.listMails = this.listSortedMails;
      } else {
        console.log(response.message);
      }

      this.loaderConfig.isLoading = false;
    },
    closeMailModal: function () {
      this._window.modalclose("#exampleModal10");
      this._window.openModal("exampleModal9");
    },
  },
});
